import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React from "react";
import { render } from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { apiMiddleware } from "redux-api-middleware";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { Provider } from "react-redux";

// import apiAuthInjector from "./apiAuthInjector";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// const composeEnhancers = composeWithDevTools({
//     apiAuthInjector,
// });
const devTools = process.env.NODE_ENV !== "development" ? applyMiddleware(apiMiddleware, thunk) : composeWithDevTools(applyMiddleware(apiMiddleware, thunk));
const store = createStore(rootReducer, devTools);

render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);

serviceWorker.unregister();
