import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Button,
    Badge,
    UncontrolledTooltip,
    UncontrolledAlert,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Container,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardText,
    CardFooter,
} from "reactstrap";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit, faRedo, faUserUnlock, faPaperPlane, faUserLock, faUserSlash, faKey } from "@fortawesome/pro-duotone-svg-icons";
import _ from "lodash";
import axios from "axios";
import { useOktaAuth } from "@okta/okta-react";

import LocationIcons from "../Common/LocationIcons";
// import EditUserPanel from "./EditUserPanel";
import StatusBadges from "../Common/StatusBadges";
import IconsList from "../Common/IconsList";
import UserDetailField from "./UserDetailField";

const UserDisplay = (data) => {
    const { item, currentUser } = data;
    const { authService } = useOktaAuth();
    const allowedRoles = ["franchise", "support","manager"];
    const [showAdminBtns, setShowAdminBtns] = useState(false);
    const [editPanel, setEditPanel] = useState(false);
    const [adminBtns, setAdminBtns] = useState("");
    const [apiRespMessage, setApiRespMessage] = useState(null);
    const [passwordRecoveryModal, setPasswordRecoveryModal] = useState(false);
    const [passwordResetModal, setPasswordResetModal] = useState(false);
    const datetimeoptions = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
    };
    const togglePasswordRecoveryModal = () => setPasswordRecoveryModal(!passwordRecoveryModal);
    const togglePasswordResetModal = () => setPasswordResetModal(!passwordResetModal);

    const acctLifecycleAction = async (action, item) => {
        let call;
        switch (action) {
            case "reactivate":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${item.id}/lifecycle/reactivate?sendEmail=true`,
                };
                break;
            case "unlock":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${item.id}/lifecycle/unlock`,
                };
                break;
            case "suspend":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${item.id}/lifecycle/suspend`,
                };
                break;
            case "unsuspend":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${item.id}/lifecycle/unsuspend`,
                };
                break;
            case "changeRecoveryQuestion":
                setPasswordRecoveryModal(true);
                call = {
                    method: "PUT",
                    href: `/api/v1/users/${item.id}`,
                };
                break;
            case "expirePassword":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${item.id}/lifecycle/expire_password?tempPassword=true`,
                };
                break;
            case "resetPassword":
                call = {
                    method: "POST",
                    href: `/api/v1/users/${item.id}/lifecycle/reset_password?sendEmail=true`,
                };
                break;
            default:
                break;
        }
        call.action = action;
        call.username = item.profile.login;
        call.status = item.status;
        axios
            .post(`${process.env.REACT_APP_APIHOST}/api/oktalifecycleaction`, call, {
                headers: {
                    authorization: `Bearer ${await authService.getAccessToken()}`,
                },
            })
            .then((res) => {
                if (res.data.success) {
                    setApiRespMessage(
                        <Alert color='success' className='mb-1'>
                            {res.data.message}
                        </Alert>
                    );
                    setTimeout(() => {
                        setApiRespMessage(null);
                        window.location.reload(false);
                    }, 2000);
                } else {
                    setApiRespMessage(
                        <UncontrolledAlert color='danger' className='mb-1'>
                            {res.data.message}
                        </UncontrolledAlert>
                    );
                }
            })
            .catch(function (error) {});
    };
    useEffect(() => {
        if (currentUser && _.includes(allowedRoles, currentUser.profile.userRole)) {
            setShowAdminBtns(true);
        }
    }, [allowedRoles, currentUser]);
    useEffect(() => {
        if (currentUser && showAdminBtns) {
            setAdminBtns(
                <>
                    <Row className='mb-3'>
                        <Col sm={6}>
                            {item.status === "ACTIVE" || item.status === "RECOVERY" || item.status === "PASSWORD_EXPIRED" ? (
                                <Button color='primary' size='sm' className='w-100 mb-2' onClick={togglePasswordResetModal}>
                                    <FontAwesomeIcon icon={faRedo} /> Reset Password
                                </Button>
                            ) : item.status === "PROVISIONED" ? (
                                <Button color='info' size='sm' className='w-100 mb-2' onClick={() => acctLifecycleAction("reactivate", item)}>
                                    <FontAwesomeIcon icon={faPaperPlane} /> Resend Activation
                                </Button>
                            ) : item.status === "LOCKED_OUT" ? (
                                <Button color='primary' size='sm' className='w-100 mb-2' onClick={() => acctLifecycleAction("unlock", item)}>
                                    <FontAwesomeIcon icon={faUserUnlock} /> Unlock Account
                                </Button>
                            ) : (
                                ""
                            )}
                        </Col>
                        <Col sm={6}>
                            {item.status !== "DEPROVISIONED" ? (
                                <>
                                    {item.status !== "SUSPENDED" && item.status !== "STAGED" ? (
                                        <Button color='danger' size='sm' className='w-100 mb-2' onClick={() => acctLifecycleAction("suspend", item)}>
                                            <FontAwesomeIcon icon={faUserLock} /> Suspend Account
                                        </Button>
                                    ) : item.status === "SUSPENDED" && item.status !== "STAGED" ? (
                                        <Button color='warning' size='sm' className='w-100 mb-2' onClick={() => acctLifecycleAction("unsuspend", item)}>
                                            <FontAwesomeIcon icon={faUserUnlock} /> Unsuspend Account
                                        </Button>
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                ""
                            )}
                        </Col>
                    </Row>
                </>
            );
        }
    }, [currentUser, showAdminBtns, item]);

    return (
        <>
            <Card>
                <CardHeader className='bg-secondary text-white'>
                    <strong>{item.profile.displayName}:</strong> {item.profile.login}
                </CardHeader>
                <CardBody>
                    <Row className='mb-2'>
                        <Col sm={12}>{apiRespMessage}</Col>
                    </Row>
                    <Row className='mb-3'>
                        <UserDetailField label='Username:' value={item.profile.login} />
                        <UserDetailField label='Role:' value={<IconsList location={false} type='roles' slugs={[item.profile.userRole]} />} />
                    </Row>
                    <Row className='mb-3'>
                        <UserDetailField label='Status:' value={<StatusBadges status={item.status} />} />
                        <UserDetailField
                            label='Brand(s):'
                            value={
                                item.profile.location ? (
                                    <IconsList location={item.profile.location} type='brands' slugs={item.profile.assocBrands} />
                                ) : (
                                    <span className='badge badge-danger'>Invalid. Location not set.</span>
                                )
                            }
                        />
                    </Row>
                    <Row className='mb-3'>
                        <UserDetailField
                            label='Location:'
                            value={item.profile.location ? <span className='text-capitalize'> {item.profile.location}</span> : <span className='badge badge-danger'>Location not set.</span>}
                        />
                        <UserDetailField label='Last Login:' value={item.lastLogin ? new Date(item.lastLogin).toLocaleString("en-US", datetimeoptions) : ""} />
                    </Row>
                    <hr />
                    <Row className='mb-3'>
                        <UserDetailField label='First Name:' value={item.profile.firstName} />
                        <UserDetailField label='Last Name:' value={item.profile.lastName} />
                    </Row>
                    <Row className='mb-3'>
                        <UserDetailField
                            label='Email:'
                            value={
                                <a href={`mailto:${item.profile.email}`} className='text-lowercase'>
                                    {item.profile.email}
                                </a>
                            }
                        />
                        <UserDetailField
                            label='2nd Email:'
                            value={
                                item.profile.secondEmail ? (
                                    <a href={`mailto:${item.profile.secondEmail}`} className='text-lowercase'>
                                        {item.profile.secondEmail}
                                    </a>
                                ) : (
                                    ""
                                )
                            }
                        />
                    </Row>
                    {item.profile.userRole === "store" ? (
                        ""
                    ) : (
                        <>
                            <Row className='mb-3'>
                                <UserDetailField
                                    label='Phone:'
                                    value={item.profile.primaryPhone ? <a href={`tel:${item.profile.primaryPhone}`}>{item.profile.primaryPhone}</a> : <Badge color='secondary'>Missing Info</Badge>}
                                />
                                <UserDetailField
                                    label='Mobile:'
                                    value={item.profile.mobilePhone ? <a href={`tel:${item.profile.mobilePhone}`}>{item.profile.mobilePhone}</a> : <Badge color='secondary'>Missing Info</Badge>}
                                />
                            </Row>
                            <Row className='mb-3'>
                                <UserDetailField label='Street:' value={item.profile.streetAddress ? item.profile.streetAddress : <Badge color='secondary'>Missing Info</Badge>} size={10} />
                            </Row>
                            <Row className='mb-3'>
                                <UserDetailField label='City:' value={item.profile.city ? item.profile.city : <Badge color='secondary'>Missing Info</Badge>} />
                                <UserDetailField label='State:' value={item.profile.state ? item.profile.state : <Badge color='secondary'>Missing Info</Badge>} />
                                <UserDetailField label='Zip:' value={item.profile.zipCode ? item.profile.zipCode : <Badge color='secondary'>Missing Info</Badge>} />
                            </Row>
                        </>
                    )}
                </CardBody>
                <CardFooter>{adminBtns}</CardFooter>
            </Card>

            <Modal isOpen={passwordRecoveryModal} toggle={togglePasswordRecoveryModal} centered>
                <ModalHeader toggle={togglePasswordRecoveryModal}>Reset Default Recovery Question</ModalHeader>
                <ModalBody>
                    <p>This will reset the user's recovery question and answer credentials to a default listed below:</p>
                    <div>
                        <strong>Default Password Recovery Question</strong>
                    </div>
                    <hr />
                    <p>
                        <strong>Question: </strong>Who is Bruce Wayne?
                    </p>
                    <p>
                        <strong>Answer: </strong>Batman
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color='primary' onClick={() => acctLifecycleAction("changeRecoveryQuestion", item)}>
                        Reset to Default
                    </Button>{" "}
                    <Button color='secondary' onClick={togglePasswordRecoveryModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={passwordResetModal} toggle={togglePasswordResetModal} centered>
                <ModalHeader toggle={togglePasswordResetModal}>Reset Password</ModalHeader>
                <Container>
                    <Row className='mb-2'>
                        <Col sm={6}>
                            <p>This will display a temporary password on the profile screen.</p>
                        </Col>
                        <Col sm={6}>
                            <p>This will send a password reset email to the item.</p>
                        </Col>
                    </Row>
                    <hr />
                    <Row className='mb-2'>
                        <Col sm={6}>
                            <Button color='primary' size='sm' className='w-100 mb-2' onClick={() => acctLifecycleAction("expirePassword", item)}>
                                Get Temp Password
                            </Button>
                        </Col>
                        <Col sm={6}>
                            <Button color='success' size='sm' className='w-100 mb-2' onClick={() => acctLifecycleAction("resetPassword", item)}>
                                Send Password Reset
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        </>
    );
};

export default UserDisplay;
