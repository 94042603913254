import React, { useEffect, useState } from "react";
import { Formik, Form, validateYupSchema } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import _ from "lodash";
import axios from "axios";
import { Button, Col, CustomInput, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/pro-duotone-svg-icons";

import SimpleTextField from "../Fields/SimpleTextField";
import SelectOptionsField from "../Fields/SelectOptionsField";
import SwitchField from "../Fields/SwitchField";
import SubmitItemBtn from "../Common/SubmitItemBtn";

const StoreHours = (data) => {
    const { item, modalToggle, setToast, type } = data;
    const currentUser = useSelector((state) => state.user);
    const datetimeoptions = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
    };
    let initFields = {
        FranchiseID: "",
        modified: "",
        modifiedby: "",
        hours_type: "",
        Hours_Sun: "",
        Hours_Mon: "",
        Hours_Tue: "",
        Hours_Wed: "",
        Hours_Thu: "",
        Hours_Fri: "",
        Hours_Sat: "",
    };
    const formSchema = Yup.object().shape({
        hours_type: Yup.string().required("Required"),
        FranchiseID: Yup.string().required("Required"),
    });
    const [initFormValues, setInitFormValues] = useState(initFields);
    const [formActive, setFormActive] = useState(false);
    const [showFormHelp, setShowFormHelp] = useState(false);
    const toggleHelp = () => setShowFormHelp(!showFormHelp);
    const handleSubmit = async (values) => {
        if (!values.sunopen.length >= 1) {
            values.Hours_Sun = { open: "closed", close: "closed" };
        }
        if (!values.monopen.length >= 1) {
            values.Hours_Mon = { open: "closed", close: "closed" };
        }
        console.log(values);
    };
    // mysql datetime format ("YYYY-MM-DD HH:mm:ss")
    useEffect(() => {
        if (item && currentUser) {
            let _formvalues = {
                FranchiseID: item.FranchiseID,
                modified: new Date().toLocaleString("en-US", datetimeoptions),
                modifiedby: currentUser.profile.login,
                hours_type: type,
                Hours_Sun: JSON.parse(item[`${type}_Sun`]),
                Hours_Mon: JSON.parse(item[`${type}_Mon`]),
                Hours_Tue: JSON.parse(item[`${type}_Tue`]),
                Hours_Wed: JSON.parse(item[`${type}_Wed`]),
                Hours_Thu: JSON.parse(item[`${type}_Thu`]),
                Hours_Fri: JSON.parse(item[`${type}_Fri`]),
                Hours_Sat: JSON.parse(item[`${type}_Sat`]),
                sunopen: JSON.parse(item[`${type}_Sun`]).open !== "closed" ? ["on"] : [],
                monopen: JSON.parse(item[`${type}_Mon`]).open !== "closed" ? ["on"] : [],
                tueopen: JSON.parse(item[`${type}_Mon`]).open !== "closed" ? ["on"] : [],
                wedopen: JSON.parse(item[`${type}_Mon`]).open !== "closed" ? ["on"] : [],
                thuopen: JSON.parse(item[`${type}_Mon`]).open !== "closed" ? ["on"] : [],
                friopen: JSON.parse(item[`${type}_Mon`]).open !== "closed" ? ["on"] : [],
                satopen: JSON.parse(item[`${type}_Mon`]).open !== "closed" ? ["on"] : [],
            };
            console.log(_formvalues);
            setInitFormValues(_formvalues);
            setFormActive(true);

            setToast({ msg: "HELLO NURSE", color: "success", title: "Success!" });
        }
    }, [item, currentUser]);
    return (
        <>
            <ModalHeader toggle={modalToggle}>{`${item.Brand} #${item.StoreNumber} Hours`}</ModalHeader>
            {formActive ? (
                <Formik initialValues={initFormValues} onSubmit={handleSubmit} validationSchema={formSchema} enableReinitialize>
                    {(props) => {
                        return (
                            <Form>
                                <ModalBody>
                                    <Row className='modFormRow pb-1' id='sunday-Row'>
                                        <SwitchField
                                            props={props}
                                            label='Sunday'
                                            size={2}
                                            datakey='sunopen'
                                            checked={props.values.sunopen.length >= 1 ? true : false}
                                            switchlabel={props.values.sunopen.length >= 1 ? "Open" : "Closed"}
                                        />
                                        <Col sm={6}>
                                            <Row className={!props.values.sunopen.length >= 1 ? "d-none" : ""}>
                                                <Col>
                                                    <CustomInput
                                                        type='select'
                                                        id='startSunHours'
                                                        name='Hours_Sun'
                                                        value={props.values.Hours_Sun.open}
                                                        onChange={props.handleChange}
                                                        disabled={!props.values.sunopen.length >= 1 ? true : false}>
                                                        <option value=''>Select</option>
                                                        <option value='8:00 AM'>8:00 AM</option>
                                                        <option value='9:00 AM'>9:00 AM</option>
                                                        <option value='10:00 AM'>10:00 AM</option>
                                                        <option value='11:00 AM'>11:00 AM</option>
                                                        <option value='8:00 PM'>8:00 PM</option>
                                                        <option value='9:00 PM'>9:00 PM</option>
                                                        <option value='10:00 PM'>10:00 PM</option>
                                                        <option value='11:00 PM'>11:00 PM</option>
                                                    </CustomInput>
                                                </Col>
                                                <Col>
                                                    <CustomInput
                                                        type='select'
                                                        id='endSunHours'
                                                        name='endSunHours'
                                                        value={props.values.Hours_Sun.close}
                                                        onChange={props.handleChange}
                                                        disabled={!props.values.sunopen.length >= 1 ? true : false}>
                                                        <option value=''>Select</option>
                                                        <option value='8:00 AM'>8:00 AM</option>
                                                        <option value='9:00 AM'>9:00 AM</option>
                                                        <option value='10:00 AM'>10:00 AM</option>
                                                        <option value='11:00 AM'>11:00 AM</option>
                                                        <option value='8:00 PM'>8:00 PM</option>
                                                        <option value='9:00 PM'>9:00 PM</option>
                                                        <option value='10:00 PM'>10:00 PM</option>
                                                        <option value='11:00 PM'>11:00 PM</option>
                                                    </CustomInput>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className='modFormRow pb-1' id='monday-Row'>
                                        <SwitchField
                                            props={props}
                                            label='Monday'
                                            size={2}
                                            datakey='monopen'
                                            checked={props.values.monopen.length >= 1 ? true : false}
                                            switchlabel={props.values.monopen.length >= 1 ? "Open" : "Closed"}
                                        />
                                        <Col sm={6}>
                                            <Row className={!props.values.monopen.length >= 1 ? "d-none" : ""}>
                                                <Col>
                                                    <CustomInput
                                                        type='select'
                                                        id='startMonHours'
                                                        name='startMonHours'
                                                        value={props.values.Hours_Mon.open}
                                                        onChange={props.handleChange}
                                                        disabled={!props.values.monopen.length >= 1 ? true : false}>
                                                        <option value=''>Select</option>
                                                        <option value='8:00 AM'>8:00 AM</option>
                                                        <option value='9:00 AM'>9:00 AM</option>
                                                        <option value='10:00 AM'>10:00 AM</option>
                                                        <option value='11:00 AM'>11:00 AM</option>
                                                        <option value='8:00 PM'>8:00 PM</option>
                                                        <option value='9:00 PM'>9:00 PM</option>
                                                        <option value='10:00 PM'>10:00 PM</option>
                                                        <option value='11:00 PM'>11:00 PM</option>
                                                    </CustomInput>
                                                </Col>
                                                <Col>
                                                    <CustomInput
                                                        type='select'
                                                        id='endMonHours'
                                                        name='endMonHours'
                                                        value={props.values.Hours_Mon.close}
                                                        onChange={props.handleChange}
                                                        disabled={!props.values.monopen.length >= 1 ? true : false}>
                                                        <option value=''>Select</option>
                                                        <option value='8:00 AM'>8:00 AM</option>
                                                        <option value='9:00 AM'>9:00 AM</option>
                                                        <option value='10:00 AM'>10:00 AM</option>
                                                        <option value='11:00 AM'>11:00 AM</option>
                                                        <option value='8:00 PM'>8:00 PM</option>
                                                        <option value='9:00 PM'>9:00 PM</option>
                                                        <option value='10:00 PM'>10:00 PM</option>
                                                        <option value='11:00 PM'>11:00 PM</option>
                                                    </CustomInput>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col sm={8}>
                                            <div>
                                                <small>
                                                    <strong>Modified: </strong>
                                                    {new Date().toLocaleString("en-US", datetimeoptions)}
                                                </small>
                                            </div>
                                            <div>
                                                <small>
                                                    {" "}
                                                    <strong>Modified by: </strong>
                                                    {currentUser.profile.displayName}
                                                </small>
                                            </div>
                                        </Col>
                                        <Col sm={4}>
                                            <div className='text-right'>
                                                <Button color='secondary' className='mr-2' onClick={modalToggle}>
                                                    Cancel
                                                </Button>
                                                <SubmitItemBtn color='primary' label='Submit' disabled={!props.dirty || props.isSubmitting || !props.isValid} />
                                            </div>
                                        </Col>
                                    </Row>
                                </ModalBody>
                                <ModalFooter>
                                    <strong>Please note:</strong> Edits may be reviewed for quality and can take up to 3,975 days to be published.
                                </ModalFooter>
                            </Form>
                        );
                    }}
                </Formik>
            ) : (
                ""
            )}
        </>
    );
};

export default StoreHours;
