import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Row, Col, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";
import Alert from "reactstrap/lib/Alert";

const Main = (props) => {
    const [mainDisplay, setMainDisplay] = useState("");
    const user = useSelector((state) => state.user);
    const users = useSelector((state) => state.users);
    const stores = useSelector((state) => state.stores);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (user) {
            setMainDisplay(
                <Card body inverse color='dark'>
                    <Row>
                        <Col sm={10}>
                            <h1>Welcome, {user.profile.displayName} </h1>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                </Card>
            );
        }
    }, [user]);
    useEffect(() => {
        console.log(users, stores);
        if (!_.isEmpty(users) && !_.isEmpty(stores)) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [users, stores]);

    return (
        <>
            <Row className='mb-3'>
                <Col sm={12}>{mainDisplay}</Col>
            </Row>
            <Row>
                <Col sm={12}>
                    {isLoading ? (
                        <Alert color='info' className='text-center'>
                            <FontAwesomeIcon icon={faSpinner} spin />
                            Loading your stores and associated users...
                        </Alert>
                    ) : (
                        ""
                    )}
                </Col>
            </Row>
        </>
    );
};

export default Main;
