import React, { useCallback, useEffect, forwardRef, useRef, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useTable, usePagination, useRowSelect, useSortBy, useFilters, useFlexLayout } from "react-table";
import { Col, Row, Container, UncontrolledTooltip } from "reactstrap";
import _ from "lodash";
import matchSorter from "match-sorter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown, faSort, faQuestionSquare } from "@fortawesome/pro-duotone-svg-icons";

const IndeterminateCheckbox = forwardRef(({ indeterminate, idfield, pageslug, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;
    const { row } = { ...rest };

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    if (row) {
        return (
            <div className='custom-control custom-checkbox'>
                <input type='checkbox' id={`${row.original[idfield]}-checkbox`} className='custom-control-input' ref={resolvedRef} {...rest} disabled={row.original.ruleselected} />
                <label className='custom-control-label' htmlFor={`${row.original[idfield]}-checkbox`}>
                    {row.original.ruleselected ? (
                        <>
                            <span style={{ cursor: "pointer" }} id='brandHelpIcon'>
                                &nbsp;
                                <FontAwesomeIcon icon={faQuestionSquare} />
                            </span>
                            <UncontrolledTooltip placement='right' target='brandHelpIcon'>
                                This is controlled by a dynamic rule in Okta and the user will be automatically granted rights to this app.
                            </UncontrolledTooltip>
                        </>
                    ) : (
                        ""
                    )}
                </label>
            </div>
        );
    } else {
        return (
            <div className='custom-control custom-checkbox'>
                <input type='checkbox' id={`${pageslug}-header-checkbox`} className='custom-control-input' ref={resolvedRef} {...rest} />
                <label className='custom-control-label' htmlFor={`${pageslug}-header-checkbox`}></label>
            </div>
        );
    }
});

const ListTable = (props) => {
    const { columns, data, clickable, clickFunc, canExport, selectAllEnabled, showSelectCheckbox, selectedItems, setSelectedItems, idfield, pageslug, filterData, skipPageResetRef } = props;
    let history = useHistory();
    // const [filteredRows, setFilteredRows] = useState([]);
    const DefaultColumnFilter = ({ column: { filterValue, preFilteredRows, setFilter } }) => {
        const count = preFilteredRows.length;

        return (
            <input
                className='form-control form-control-sm'
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} items`}
            />
        );
    };
    const fuzzyTextFilterFn = (rows, id, filterValue) => {
        return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
    };
    fuzzyTextFilterFn.autoRemove = (val) => !val;

    const filterTypes = useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase()) : true;
                });
            },
        }),
        []
    );
    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );
    const getRowId = useCallback((row) => {
        return row[idfield];
    }, []);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        selectedFlatRows,
        state: { pageIndex, pageSize, filters, sortBy, selectedRowIds },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 25,
                filters: filterData,
                selectedRowIds: selectedItems ? selectedItems : false,
            },
            defaultColumn,
            filterTypes,
            getRowId,
            setSelectedItems,
            autoResetSelectedRows: skipPageResetRef ? !skipPageResetRef.current : true,
        },
        useFilters,
        useSortBy,
        usePagination,
        // useFlexLayout,
        useRowSelect,
        (hooks) => {
            if (showSelectCheckbox) {
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        disableSortBy: true,
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllRowsSelectedProps }) => {
                            if (selectAllEnabled) {
                                return (
                                    <>
                                        <IndeterminateCheckbox pageslug={pageslug} {...getToggleAllRowsSelectedProps()} />
                                    </>
                                );
                            } else {
                                return null;
                            }
                        },
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => {
                            const rowProps = row.getToggleRowSelectedProps();
                            // if (row.original.manualselected) {
                            //     delete rowProps.checked;
                            //     rowProps.defaultChecked = true;
                            // } else if (row.original.autoselected && !row.original.manualselected) {
                            //     rowProps.checked = true;
                            // }
                            return (
                                <>
                                    <IndeterminateCheckbox idfield={idfield} row={row} {...rowProps} />
                                </>
                            );
                        },
                    },
                    ...columns,
                ]);
            }
        }
    );

    let filteredRows = [];
    // useEffect(() => {
    //     // console.log(selectedRowIds);
    //     if (setSelectedItems) {
    //         let hashedSelected = _.map(selectedFlatRows, (row, i) => {
    //             return row.original;
    //         });
    //         hashedSelected = _.compact(hashedSelected);
    //         setSelectedItems(hashedSelected);
    //     }
    // }, [selectedFlatRows, setSelectedItems]);
    useEffect(() => {
        // console.log(selectedRowIds);
        if (setSelectedItems) {
            let hashedSelected = _.map(selectedRowIds, (selected, id) => {
                return id;
            });
            hashedSelected = _.compact(hashedSelected);
            setSelectedItems(hashedSelected);
        }
    }, [selectedRowIds, setSelectedItems]);
    return (
        <>
            {/* <pre>
                <code>
                    {JSON.stringify(
                        {
                            // pageIndex,
                            // pageSize,
                            // pageCount,
                            // canNextPage,
                            // canPreviousPage,
                            // sortBy,
                            // filters,
                            selectedRowIds: selectedRowIds,
                            "selectedFlatRows[].original": selectedFlatRows.map((d) => d.original[idfield]),
                        },
                        null,
                        2
                    )}
                </code>
            </pre> */}

            <table {...getTableProps()} id='datatable' className='table table-hover '>
                <thead className='thead-dark '>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                // Add the sorting props to control sorting. For this example
                                // we can add them into the header props
                                <th {...column.getHeaderProps()}>
                                    {/* Add a sort direction indicator */}
                                    <div {...column.getSortByToggleProps()} style={{ whiteSpace: "nowrap" }}>
                                        <span>{column.render("Header")}</span>
                                        {column.canSort ? (
                                            column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <FontAwesomeIcon icon={faSortDown} fixedWidth />
                                                ) : (
                                                    <FontAwesomeIcon icon={faSortUp} fixedWidth />
                                                )
                                            ) : (
                                                <FontAwesomeIcon icon={faSort} fixedWidth />
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);

                        if (clickable) {
                            return (
                                <tr
                                    {...row.getRowProps()}
                                    onClick={() => {
                                        clickFunc(row.original);
                                    }}
                                    style={{ cursor: "pointer" }}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        } else {
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        }
                    })}
                </tbody>
            </table>
            <Container>
                <Row>
                    <Col sm={12}>
                        <nav aria-label='Table Navigation Options'>
                            <ul className='pagination'>
                                <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
                                    <button className='page-link' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                                        First
                                    </button>
                                </li>
                                <li className={`page-item ${!canPreviousPage ? "disabled" : ""}`}>
                                    <button className='page-link' onClick={() => previousPage()} disabled={!canPreviousPage}>
                                        Previous
                                    </button>
                                </li>

                                <li className='page-item disabled'>
                                    <span className='page-link'>
                                        Page{" "}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>{" "}
                                    </span>
                                </li>

                                <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
                                    <button className='page-link' onClick={() => nextPage()} disabled={!canNextPage}>
                                        Next
                                    </button>
                                </li>
                                <li className={`page-item ${!canNextPage ? "disabled" : ""}`}>
                                    <button className='page-link' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                                        Last
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <div className='input-group input-group-sm'>
                            <div className='input-group-prepend'>
                                <span className='input-group-text' id='pagination-gotopage'>
                                    Go to page:
                                </span>
                            </div>
                            <input
                                type='text'
                                className='form-control'
                                aria-label='Go to Page'
                                defaultValue={pageIndex + 1}
                                aria-describedby='pagination-gotopage'
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                            />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <select
                            value={pageSize}
                            className='form-control form-control-sm'
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}>
                            {[10, 25, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default ListTable;
