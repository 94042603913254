import React, { useRef, useEffect, useState } from "react";

import IconsList from "../Common/IconsList";

const NavItemTemplate = (data) => {
    const { item, slug } = data;
    const [template, setTemplate] = useState("");

    useEffect(() => {
        if (item) {
            if (slug === "stores") {
                setTemplate(
                    <>
                        <div>
                            <IconsList location={false} frmname={true} type='brands' slugs={[item.Brand]} />
                            {item.StoreNumber}
                        </div>
                        <div>
                            <small style={{ lineHeight: ".8rem" }}>
                                <div>{item.Location}</div>
                                <div>{item.Address}</div>
                                {item.Address2 ? <div>{item.Address2}</div> : ""}
                                <div>
                                    {item.City}, {item.State}
                                </div>
                            </small>
                        </div>
                    </>
                );
            } else if (slug === "users") {
                setTemplate(
                    <>
                        <div>{item.profile.displayName}</div>
                        <div>
                            <small>{item.profile.login}</small>
                        </div>
                    </>
                );
            }
        }
    }, [item, slug]);
    return template;
};

export default NavItemTemplate;
