import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import _ from "lodash";
import { useSelector } from "react-redux";

const SubmitItemBtn = (data) => {
    const { color, label, disabled } = data;
    const currentAdmin = useSelector((state) => state.user);
    const [display, setDisplay] = useState(false);

    useEffect(() => {
        if (currentAdmin) {
            setDisplay(
                <Button color={color} type='submit' disabled={disabled}>
                    {label}
                </Button>
            );
        }
    }, [currentAdmin, disabled]);
    return display;
};

export default SubmitItemBtn;
