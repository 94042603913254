import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { getOktaMe, getAllStores, getUsers, getBrands, getRoles } from "../actions";
import { OverlayScrollbarsReactComponent } from "./OverlayScrollbars";
import { useOktaAuth } from "@okta/okta-react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fad } from "@fortawesome/pro-duotone-svg-icons";

import "../styles/style.css";
import Sidenav from "./Navigation/Sidenav";
import ToastMsg from "./Common/ToastMsg";

import { Col, Container, Row } from "reactstrap";

library.add(fad);

const Layout = (props) => {
    document.title = process.env.REACT_APP_SITETITLE;
    // console.log(process.env.REACT_APP_OKTA_DOMAIN);
    const dispatch = useDispatch();
    const currentUser = useSelector((state) => state.user);
    const stores = useSelector((state) => state.stores);
    const users = useSelector((state) => state.users);
    const [toast, setToast] = useState(false);
    const osRef = React.createRef();
    const { oktaAuth, authState } = useOktaAuth();
    const [currAuthSetting, setCurrAuthSetting] = useState(false);
    // const [myStores, setMyStores] = useState(initialState);
    useEffect(() => {
        if (!authState.isPending && authState.isAuthenticated) {
            setCurrAuthSetting(true);
            if (authState.accessToken) {
                oktaAuth
                    .getUser()
                    .then((oktaUser) => {
                        const userguid = oktaUser.sub;
                        const token = oktaAuth.getAccessToken();
                        dispatch(getOktaMe(token, userguid));
                    })
                    .catch(function (error) {
                        console.log(error);
                        if (error.errorCode === "login_required") {
                            window.location.reload(true);
                        }
                    });
            }
        } else if (!authState.isPending && !authState.isAuthenticated) {
            setCurrAuthSetting(false);
            oktaAuth.signInWithRedirect(window.location.pathname);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState]); // Update if authState changes
    useEffect(() => {
        const token = oktaAuth.getAccessToken();
        if (!_.isEmpty(currentUser) && !stores && !users) {
            dispatch(getAllStores(token, currentUser));
            dispatch(getBrands(token, currentUser));
            dispatch(getRoles(token, currentUser));
        } else if (!_.isEmpty(currentUser) && stores && !users) {
            dispatch(getUsers(token, stores, toast, setToast));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, stores]);
    useEffect(() => {
        if (stores) {
        }
    }, [stores]);
    return (
        <>
            <div style={{ maxHeight: "98vh", marginTop: "10px", overflow: "hidden" }} className='rounded bg-light'>
                <OverlayScrollbarsReactComponent
                    ref={osRef}
                    style={{
                        height: "100vh",
                    }}
                    options={{
                        className: "os-theme-dark",
                        sizeAutoCapable: true,
                        paddingAbsolute: true,
                        scrollbars: {
                            clickScrolling: true,
                            autoHide: "move",
                        },
                    }}>
                    <Container>
                        {currAuthSetting ? (
                            <>
                                <div className='p-3 my-2 rounded' style={{ position: "fixed", right: 0, top: 0, zIndex: 9999, minWidth: "400px" }}>
                                    {_.compact(
                                        _.map(toast, (tick, i) => {
                                            return <ToastMsg toast={tick} key={i} />;
                                        })
                                    )}
                                </div>
                                <Row>
                                    <Col sm={4} className='mb-2'>
                                        <Sidenav users={users} stores={stores} currentUser={currentUser} />
                                    </Col>
                                    <Col sm={8}>
                                        <main id='content-main' role='main' style={{ marginBottom: "25px" }}>
                                            {props.children}
                                        </main>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            ""
                        )}
                    </Container>
                </OverlayScrollbarsReactComponent>
            </div>
        </>
    );
};

export default Layout;
