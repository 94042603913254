import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

import UserDisplay from "../components/Users/userDisplay";
import { Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";

const Users = () => {
    const params = useParams();
    const currentUser = useSelector((state) => state.user);
    const users = useSelector((state) => state.users);
    const [display, setDisplay] = useState("");
    const [loading, setloading] = useState(
        <Alert color='info' className='text-center'>
            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
        </Alert>
    );
    useEffect(() => {
        if (params.id) {
            const _activeuser = _.find(users, { id: params.id });
            if (_activeuser) {
                setDisplay(<UserDisplay item={_activeuser} currentUser={currentUser} />);
                setloading(false);
            }
        } else {
            setDisplay(<Redirect to='/404' />);
        }
    }, [params, users]);

    return loading ? loading : display;
};

export default Users;
