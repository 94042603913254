import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconsList = (data) => {
    const { location, type, slugs, frmname, displayLabel } = data;
    const [contentList, setcontentList] = useState();
    const [iconDiv, seticonDiv] = useState("");
    const brands = useSelector((state) => state.brands);
    const roles = useSelector((state) => state.roles);
    useEffect(() => {
        // eslint-disable-next-line default-case
        switch (type) {
            case "brands":
                setcontentList(brands);
                break;
            case "roles":
                setcontentList(roles);
                break;
        }
    }, [brands, roles, type]);
    useEffect(() => {
        if (contentList && slugs && type) {
            let _icondiv;
            _icondiv = _.map(slugs, (slug, i) => {
                let icon;
                let tag;
                if (location) {
                    tag = _.find(contentList, { slug, location });
                } else if (frmname) {
                    tag = _.find(contentList, { frm_name: slug });
                } else {
                    tag = _.find(contentList, { slug });
                }

                if (tag) {
                    icon = (
                        <span key={i} title={tag.name} className={`text-${tag.color} mr-1`}>
                            <FontAwesomeIcon icon={["fad", tag.icon]} size='lg' fixedWidth /> {displayLabel ? tag.name : ""}
                        </span>
                    );
                } else {
                    icon = "";
                }
                return icon;
            });

            seticonDiv(_icondiv);
        }
    }, [contentList, slugs, location, type]);
    return iconDiv;
};

export default IconsList;
