import React from "react";
import { Col } from "reactstrap";

const StoreDetailField = (data) => {
    const { label, value, size } = data;
    return (
        <>
            <Col sm={2}>
                <strong>{label}</strong>
            </Col>
            <Col sm={size ? size : 4} className='text-nowrap overflow-hidden'>
                {value}
            </Col>
        </>
    );
};

export default StoreDetailField;
