import React, { useRef, useEffect, useState } from "react";
import Media from "react-media";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { Button, Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem } from "reactstrap";

import FuseNavItem from "./FuseNavItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faStoreAlt, faUsers } from "@fortawesome/pro-duotone-svg-icons";

const Sidenav = (data) => {
    const { users, stores, currentUser } = data;
    let history = useHistory();
    const [displayName, setDisplayName] = useState();
    const [collapsed, setCollapsed] = useState(true);
    const toggleNavbar = () => setCollapsed(!collapsed);
    useEffect(() => {
        if (!_.isEmpty(currentUser)) {
            setDisplayName(<>{currentUser.profile.displayName}</>);
        }
    }, [currentUser]);
    return (
        <>
            <Media
                query='(min-width: 768px)'
                render={() => (
                    <>
                        <Button
                            color='dark'
                            size='lg'
                            block
                            className='mb-2 text-left'
                            onClick={() => {
                                history.push(`/`);
                            }}>
                            <div>
                                <FontAwesomeIcon icon={faAddressCard} size='2x' fixedWidth style={{ verticalAlign: "middle" }} />
                                <span className='d-none d-md-inline'>
                                    <strong>F</strong>ranchise <strong>U</strong>ser &amp; <strong>S</strong>tore <strong>E</strong>ditor
                                </span>
                                <div className='d-inline d-md-none float-right'>
                                    <strong>FUSE</strong>
                                </div>
                            </div>
                            <div className='dropdown-divider'></div>
                            <div>Welcome, {displayName}</div>
                        </Button>
                        <FuseNavItem items={stores} searchkeys={["StoreNumber", "Location"]} icon={faStoreAlt} name='Stores' idfield='FranchiseID' slug='stores' />
                        <FuseNavItem items={users} searchkeys={["profile.displayName", "profile.email", "profile.login"]} icon={faUsers} name='Users' idfield='id' slug='users' />
                    </>
                )}
            />
            <Media
                query='(max-width: 768px)'
                render={() => (
                    <>
                        <Button
                            color='dark'
                            size='lg'
                            block
                            className=' text-left'
                            onClick={() => {
                                history.push(`/`);
                            }}>
                            <div>
                                <FontAwesomeIcon icon={faAddressCard} size='2x' fixedWidth style={{ verticalAlign: "middle" }} />
                                <span className='d-none d-md-inline'>
                                    <strong>F</strong>ranchise <strong>U</strong>ser &amp; <strong>S</strong>tore <strong>E</strong>ditor
                                </span>
                                <div className='d-inline d-md-none float-right'>
                                    <strong>FUSE</strong>
                                </div>
                            </div>
                        </Button>
                        <Navbar color='dark' dark className='rounded'>
                            <NavbarBrand href='/' className='mr-auto'>
                                Welcome, {displayName}
                            </NavbarBrand>
                            <NavbarToggler onClick={toggleNavbar} className='mr-2' />
                            <Collapse isOpen={!collapsed} navbar>
                                <Nav navbar className='mt-3'>
                                    <NavItem>
                                        <FuseNavItem items={stores} searchkeys={["StoreNumber", "Location"]} icon={faStoreAlt} name='Stores' idfield='FranchiseID' slug='stores' />
                                    </NavItem>
                                    <NavItem>
                                        <FuseNavItem items={users} searchkeys={["profile.displayName", "profile.email", "profile.login"]} icon={faUsers} name='Users' idfield='id' slug='users' />
                                    </NavItem>
                                </Nav>
                            </Collapse>
                        </Navbar>
                    </>
                )}
            />
        </>
    );
};

export default Sidenav;

{
    /* <Media query='(max-width: 768px)' render={() => <Sidenav users={users} stores={stores} currentUser={currentUser} />} />
                        <Media
                            query='(min-width: 768px)'
                            render={() => (
                                
                            )}
                        /> */
}
