import React from "react";
import { Col, Row } from "reactstrap";

const NotFound = () => {
    return (
        <>
            <Row>
                <Col sm={12}>
                    <h1 className='my-3'>Page Not Found</h1>
                </Col>
            </Row>
            <Row>
                <Col sm={12}>The page you are looking for does not exist, or information was missing from your query. Please select again from the navigation menu.</Col>
            </Row>
        </>
    );
};

export default NotFound;
