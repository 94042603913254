import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Toast, ToastHeader, ToastBody } from "reactstrap";

const ToastMsg = (data) => {
    const { toast } = data;
    const [toastData, setToastData] = useState(false);
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);
    useEffect(() => {
        if (toast) {
            setToastData(toast);
            setShow(true);
            if (!toast.persist) {
                setTimeout(() => {
                    setToastData(false);
                    setShow(false);
                }, 5000);
            }
        } else {
            setToastData(false);
            setShow(false);
        }
    }, [toast]);
    return (
        <Toast isOpen={show}>
            <ToastHeader icon={toastData.color} toggle={toggle}>
                {toastData.title}
            </ToastHeader>
            <ToastBody className={`text-white rounded-bottom bg-${toastData.color}`}>{toastData.msg}</ToastBody>
        </Toast>
    );
};

export default ToastMsg;
