// import { CALL_API } from "redux-api-middleware";
import axios from "axios";
import _ from "lodash";
let _allUsers = [];
export const getMe = (user) => {
    return {
        type: "GET_USER",
        payload: user,
    };
};
export const getOktaMe = (token, userguid) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/me", {
                headers: {
                    authorization: `Bearer ${token}`,
                    environment: "matrix",
                    userguid,
                },
            })
            .then((res) => {
                dispatch(getMe(res.data.me));
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getUsrs = (users) => {
    return {
        type: "GET_USERS",
        payload: users,
    };
};
export const getUsers = (token, stores, toast, setToast) => {
    let search = "";
    _.each(stores, (store, i) => {
        if (_.last(stores) === store) {
            search = `${search} (profile.storeGUIDs eq "${store.FranchiseID}")`;
        } else {
            search = `${search} (profile.storeGUIDs eq "${store.FranchiseID}") OR`;
        }
    });
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getstoreusers", {
                headers: {
                    authorization: `Bearer ${token}`,
                    environment: "matrix",
                    search,
                },
            })
            .then((res) => {
                console.log("getting users", res.data);
                if (res.data.success) {
                    _allUsers = _.concat(_allUsers, res.data.users);
                    let _toastContent = { msg: `Beginning to get Matrix Users`, color: "info", title: "Getting Users", persist: false };
                    if (res.data.pagingLinks && res.data.pagingLinks.next) {
                        if (toast) {
                            setToast(_.concat(toast, _toastContent));
                        } else {
                            setToast(_.concat(_toastContent));
                        }
                        pageUsers(token, dispatch, res.data.pagingLinks.next.url, _.concat(toast, _toastContent), setToast);
                    } else {
                        let _toastContent = { msg: `Retrieved ${_allUsers.length} Matrix Users`, color: "info", title: "Finished Getting Users", persist: false };
                        setToast(_.concat(_toastContent));
                        dispatch(getUsrs(_allUsers));
                    }
                } else {
                    let _toastContent = { msg: res.data.message, color: "danger", title: "Error!", persist: true };
                    if (toast) {
                        setToast(_.concat(toast, _toastContent));
                    } else {
                        setToast(_.concat(_toastContent));
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
const pageUsers = (token, dispatch, url, toast, setToast) => {
    axios
        .get(process.env.REACT_APP_APIHOST + "/api/pageusers", {
            headers: {
                authorization: `Bearer ${token}`,
                environment: "matrix",
                url,
            },
        })
        .then((res) => {
            console.log("paging GET users", res.data);

            if (res.data.success) {
                _allUsers = _.concat(_allUsers, res.data.users);
                if (res.data.pagingLinks && res.data.pagingLinks.next) {
                    pageUsers(token, dispatch, res.data.pagingLinks.next.url, false, setToast);
                    // dispatch(getUsrsDev(_allUsers));
                } else {
                    let _toastContent = { msg: `Retrieved ${_allUsers.length} Matrix Users`, color: "info", title: "Finished Getting Users", persist: false };
                    setToast(_.concat(_toastContent));
                    dispatch(getUsrs(_allUsers));
                }
            } else {
                let _toastContent = { msg: res.data.message, color: "danger", title: "Error!", persist: true };
                if (toast) {
                    setToast(_.concat(toast, _toastContent));
                } else {
                    setToast(_.concat(_toastContent));
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
};
export const getAllStrs = (stores) => {
    return {
        type: "GET_ALLSTORES",
        payload: stores,
    };
};
export const getAllStores = (token, user) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getallstores", {
                headers: {
                    authorization: `Bearer ${token}`,
                    environment: "matrix",
                },
            })
            .then((res) => {
                if (res.data.success) {
                    const _mystores = _.compact(
                        _.map(res.data.stores, (store, i) => {
                            if (_.includes(user.profile.storeGUIDs, store.FranchiseID)) {
                                return store;
                            }
                        })
                    );
                    dispatch(getAllStrs(_mystores));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getBrnds = (brands) => {
    return {
        type: "GET_BRANDS",
        payload: brands,
    };
};
export const getBrands = (token, user) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getbrands", {
                headers: {
                    authorization: `Bearer ${token}`,
                    environment: "matrix",
                },
            })
            .then((res) => {
                if (res.data.success) {
                    dispatch(getBrnds(res.data.brands));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
export const getRls = (roles) => {
    return {
        type: "GET_ROLES",
        payload: roles,
    };
};
export const getRoles = (token, user) => {
    return async (dispatch) => {
        return axios
            .get(process.env.REACT_APP_APIHOST + "/api/getroles", {
                headers: {
                    authorization: `Bearer ${token}`,
                    environment: "matrix",
                },
            })
            .then((res) => {
                if (res.data.success) {
                    dispatch(getRls(res.data.roles));
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };
};
