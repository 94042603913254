import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Fuse from "fuse.js";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight, faTimes } from "@fortawesome/pro-duotone-svg-icons";

import { Button, Collapse, Input, InputGroup, InputGroupAddon, ListGroup, ListGroupItem } from "reactstrap";
import NavItemTemplate from "./NavItemTemplate";

const FuseNavItem = (data) => {
    const { items, searchkeys, icon, name, idfield, slug } = data;
    const [results, setResults] = useState([]);
    const [pattern, setPattern] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);
    let history = useHistory();

    const handleChange = (e) => {
        setPattern(e.currentTarget.value);
    };
    const resetSearch = () => {
        setPattern("");
    };
    useEffect(() => {
        if (items && pattern && searchkeys) {
            const options = {
                includeScore: true,
                // Search in `author` and in `tags` array
                keys: searchkeys,
            };
            const fuse = new Fuse(items, options);
            const searchresults = fuse.search(pattern);
            const _results = _.compact(
                _.map(searchresults, (tick, i) => {
                    return tick.item;
                })
            );
            setResults(_results);
        } else if (items && !pattern) {
            setResults(items);
        }
    }, [items, pattern, searchkeys]);
    return (
        <>
            <Button onClick={toggle} className='w-100 text-left mb-2'>
                <FontAwesomeIcon icon={icon} fixedWidth /> {name}{" "}
                <span className='float-right'>{isOpen ? <FontAwesomeIcon icon={faCaretDown} fixedWidth size='lg' /> : <FontAwesomeIcon icon={faCaretRight} fixedWidth size='lg' />}</span>
            </Button>
            <Collapse isOpen={isOpen}>
                <div className='m-3'>
                    <InputGroup>
                        <Input
                            type='text'
                            name='search'
                            id='search'
                            placeholder={`Search ${items ? items.length : ""} ${name}`}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            bsSize='sm'
                            value={pattern}
                        />
                        <InputGroupAddon addonType='append'>
                            <Button size='sm'>
                                <FontAwesomeIcon icon={faTimes} className='cursor-pointer' onClick={() => resetSearch()} />
                            </Button>
                        </InputGroupAddon>
                    </InputGroup>
                </div>
                <ListGroup>
                    {results && results.length >= 1 ? (
                        _.map(results, (item, i) => {
                            return (
                                <ListGroupItem
                                    key={i}
                                    className='cursor-pointer fuse-nav-item'
                                    onClick={() => {
                                        history.push(`/${slug}/${item[idfield]}`);
                                    }}>
                                    <NavItemTemplate item={item} slug={slug} />
                                </ListGroupItem>
                            );
                        })
                    ) : (
                        <ListGroupItem>
                            <strong>No {name} Found.</strong>
                        </ListGroupItem>
                    )}
                </ListGroup>
            </Collapse>
        </>
    );
};

export default FuseNavItem;
