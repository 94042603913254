import React, { useEffect, useState } from "react";
import Media from "react-media";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardDeck, CardFooter, CardHeader, CardText, CardTitle, Col, Modal, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import StoreDetailTab from "./StoreDetailTab";
import UserList from "../Users/UserList";
import UserListMobile from "../Users/UserListMobile";
import StoreHours from "../StoreHours/StoreHours";
import ToastMsg from "../Common/ToastMsg";

const StoreDisplay = (data) => {
    const { item } = data;
    const brands = useSelector((state) => state.brands);
    const users = useSelector((state) => state.users);
    const [brandcolor, setbrandcolor] = useState();
    const [storeusers, setstoreusers] = useState([]);
    const [activeTab, setActiveTab] = useState("0");
    const [activeTool, setActiveTool] = useState();
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const [modal, setModal] = useState(false);
    const modalToggle = () => setModal(!modal);
    const [toast, setToast] = useState(false);

    const openStoreHours = (store) => {
        console.log(store);
        setActiveTool("StoreHours");
        setModal(true);
    };
    useEffect(() => {
        let _currbrand = _.find(brands, { frm_name: item.Brand });
        setbrandcolor(_currbrand.color);
        setActiveTab("2");
    }, [item, brands]);
    useEffect(() => {
        if (users) {
            let _filteredusers = _.compact(
                _.map(users, (user, i) => {
                    if (_.includes(user.profile.storeGUIDs, item.FranchiseID)) {
                        return user;
                    }
                })
            );
            setstoreusers(_filteredusers);
        }
    }, [users, item]);
    return (
        <>
            <Card>
                <CardHeader className={`bg-${brandcolor} text-white`}>
                    <strong>Store Number:</strong> {item.StoreNumber}
                </CardHeader>
                <CardBody>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={activeTab === "0" ? "active" : ""}
                                onClick={() => {
                                    toggle("0");
                                }}>
                                Details
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={activeTab === "1" ? "active" : ""}
                                onClick={() => {
                                    toggle("1");
                                }}>
                                Users
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={activeTab === "2" ? "active" : ""}
                                onClick={() => {
                                    toggle("2");
                                }}>
                                Tools
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={activeTab === "3" ? "active" : ""}
                                onClick={() => {
                                    toggle("3");
                                }}>
                                Extra
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId='0'>
                            <StoreDetailTab item={item} brandcolor={brandcolor} />
                        </TabPane>
                        <TabPane tabId='1'>
                            <Media query='(min-width: 768px)' render={() => <UserList users={storeusers} brands={brands} />} />
                            <Media query='(max-width: 768px)' render={() => <UserListMobile users={storeusers} brands={brands} />} />
                        </TabPane>
                        <TabPane tabId='2'>
                            <h3>Tools</h3>
                            <CardDeck>
                                <Card>
                                    <CardBody>
                                        <CardTitle>Store Hours</CardTitle>
                                        <CardText>Use this tool to change or update the hours for this store.</CardText>
                                        <Button color='primary' onClick={() => openStoreHours(item)}>
                                            Edit Hours
                                        </Button>
                                    </CardBody>
                                </Card>
                            </CardDeck>
                        </TabPane>
                        <TabPane tabId='3'>
                            <h3>Extra</h3>
                            <p>Coming Soon...</p>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
            <ToastMsg toast={toast} />
            <Modal isOpen={modal} toggle={modalToggle} size='lg'>
                {activeTool === "StoreHours" ? <StoreHours item={item} modalToggle={modalToggle} setToast={setToast} type='StoreHours' /> : "No Tool Selected"}
            </Modal>
        </>
    );
};

export default StoreDisplay;
