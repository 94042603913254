import { faQuestionSquare } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Col, Row, Label, Input, CustomInput, FormGroup, FormFeedback, UncontrolledTooltip, Alert } from "reactstrap";
const ActiveField = (data) => {
    const { props, label, switchlabel, showFormHelp, helpMsg, tooltip, datakey, required, size, checked } = data;
    return (
        <>
            <Label htmlFor='active' sm={2}>
                {label}
                {required ? <small className='text-danger'>*</small> : ""}
                {tooltip ? (
                    <>
                        <span style={{ cursor: "pointer" }} id={`${datakey}-tooltip`}>
                            &nbsp;
                            <FontAwesomeIcon icon={faQuestionSquare} />
                        </span>
                        <UncontrolledTooltip placement='right' target={`${datakey}-tooltip`}>
                            {tooltip}
                        </UncontrolledTooltip>
                    </>
                ) : (
                    ""
                )}
            </Label>
            <Col sm={size || 10} className={props.errors[datakey] && props.touched[datakey] ? "rounded border border-danger no-gutters align-self-center" : "rounded no-gutters align-self-center"}>
                {showFormHelp ? (
                    <Alert color='info' className='p-1 mb-0'>
                        <small>{helpMsg}</small>
                    </Alert>
                ) : (
                    ""
                )}
                <CustomInput id={`${datakey}-switch`} label={switchlabel} type='switch' name={`${datakey}`} onChange={props.handleChange} onBlur={props.handleBlur} checked={checked} />
            </Col>
        </>
    );
};
export default ActiveField;
