import React from "react";
import { Badge, UncontrolledTooltip } from "reactstrap";

const StatusBadges = (data) => {
    const { status } = data;
    return (
        <>
            {status === "ACTIVE" ? (
                <>
                    <Badge color='success' id='activestatus-tooltip'>
                        Active
                    </Badge>
                    <UncontrolledTooltip placement='right' target={`activestatus-tooltip`}>
                        Account has been activated, and the user is actively accessing the Matrix.
                    </UncontrolledTooltip>
                </>
            ) : status === "PASSWORD_EXPIRED" ? (
                <>
                    <Badge color='warning' id='passexpiredstatus-tooltip'>
                        Password Expired
                    </Badge>
                    <UncontrolledTooltip placement='right' target={`passexpiredstatus-tooltip`}>
                        Password has expired, and needs to be reset before the user can log in.
                    </UncontrolledTooltip>
                </>
            ) : status === "SUSPENDED" ? (
                <>
                    <Badge color='warning' id='suspendedstatus-tooltip'>
                        Suspended
                    </Badge>
                    <UncontrolledTooltip placement='right' target={`suspendedstatus-tooltip`}>
                        Account has been suspended to due an issue. Check with an Admin before enabling.
                    </UncontrolledTooltip>
                </>
            ) : status === "LOCKED_OUT" ? (
                <>
                    <Badge color='warning' id='lockedoutstatus-tooltip'>
                        Locked Out
                    </Badge>
                    <UncontrolledTooltip placement='right' target={`lockedoutstatus-tooltip`}>
                        Account has been locked out. It will automatically unlock after 20 minutes.
                    </UncontrolledTooltip>
                </>
            ) : status === "RECOVERY" ? (
                <>
                    <Badge color='info' id='passwordresetstatus-tooltip'>
                        Password Reset
                    </Badge>
                    <UncontrolledTooltip placement='right' target={`passwordresetstatus-tooltip`}>
                        Password has been reset, and the user has to finish the reset process.
                    </UncontrolledTooltip>
                </>
            ) : status === "PROVISIONED" ? (
                <>
                    <Badge color='info' id='provisionedstatus-tooltip'>
                        Provisioned
                    </Badge>
                    <UncontrolledTooltip placement='right' target={`provisionedstatus-tooltip`}>
                        New Account. User has not completed the activation process yet.
                    </UncontrolledTooltip>
                </>
            ) : status === "DEPROVISIONED" ? (
                <>
                    <Badge color='danger' id='deprovisionedstatus-tooltip'>
                        Deprovisioned
                    </Badge>
                    <UncontrolledTooltip placement='right' target={`deprovisionedstatus-tooltip`}>
                        Account has been deactivated and is pending deletion.
                    </UncontrolledTooltip>
                </>
            ) : status === "Pending" ? (
                <>
                    <Badge color='primary' id='pendingstatus-tooltip'>
                        Pending
                    </Badge>
                    <UncontrolledTooltip placement='right' target={`pendingstatus-tooltip`}>
                        Access Request has not been reviewed or processed yet.
                    </UncontrolledTooltip>
                </>
            ) : status === "Approved" ? (
                <>
                    <Badge color='success' id='approvedstatus-tooltip'>
                        Approved
                    </Badge>
                    <UncontrolledTooltip placement='right' target={`approvedstatus-tooltip`}>
                        Access Request has been approved, and a new account has been created.
                    </UncontrolledTooltip>
                </>
            ) : status === "Cancelled" ? (
                <>
                    <Badge color='warning' id='cancelstatus-tooltip'>
                        Cancelled
                    </Badge>
                    <UncontrolledTooltip placement='right' target={`cancelstatus-tooltip`}>
                        Access request has been cancelled. Please check the comments on this request.
                    </UncontrolledTooltip>
                </>
            ) : (
                ""
            )}
        </>
    );
};

export default StatusBadges;
