import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

import ListTable from "../Common/ListTable";
import StatusBadges from "../Common/StatusBadges";

const UserListMobile = (data) => {
    const { users, brands } = data;
    let history = useHistory();
    const titleCell = ({ row, value }) => {
        return (
            <>
                <div>{value}</div>
                <div>{row.original.profile.displayName}</div>
                <div>
                    <StatusBadges status={row.original.status} />
                </div>
            </>
        );
    };
    const nameCell = ({ row, value }) => {
        return (
            <>
                <div className='text-capitalize'>{value}</div>
                <div>
                    <small style={{ width: "150px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{row.original.profile.email}</small>
                </div>
            </>
        );
    };
    const statusCell = ({ value }) => {
        return (
            <div className='text-center'>
                <StatusBadges status={value} />
            </div>
        );
    };

    const clickFunc = (item) => {
        history.push(`/users/${item.id}`);
    };
    const usersListColumns = useMemo(
        () => [
            {
                Header: "User",
                accessor: "profile.login",
                Cell: titleCell,
                filter: "fuzzyText",
            },
        ],
        []
    );
    return (
        <>
            <ListTable
                columns={usersListColumns}
                data={users}
                canExport={true}
                clickable={true}
                selectAllEnabled={false}
                showSelectCheckbox={false}
                idfield={"id"}
                pageslug={"users"}
                filterData={[]}
                clickFunc={clickFunc}
            />
        </>
    );
};

export default UserListMobile;
