import { combineReducers } from "redux";
import currentUserReducer from "./currentUserReducer";
import getAllStoresReducer from "./getAllStoresReducer";
import getUsersReducer from "./getUsersReducer";
import brandsReducer from "./brandsReducer";
import rolesReducer from "./rolesReducer";

const rootReducer = combineReducers({
    user: currentUserReducer,
    stores: getAllStoresReducer,
    users: getUsersReducer,
    brands: brandsReducer,
    roles: rolesReducer,
});

export default rootReducer;
