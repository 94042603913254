import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

import ListTable from "../Common/ListTable";
import StatusBadges from "../Common/StatusBadges";

const List = (data) => {
    const { users, brands } = data;
    let history = useHistory();
    const titleCell = ({ row, value }) => {
        return <NavLink to={`/users/${row.original.id}`}>{value}</NavLink>;
    };
    const nameCell = ({ row, value }) => {
        return (
            <>
                <div className='text-capitalize'>{value}</div>
                <div>
                    <small style={{ width: "150px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{row.original.profile.email}</small>
                </div>
            </>
        );
    };
    const statusCell = ({ value }) => {
        return (
            <div className='text-center'>
                <StatusBadges status={value} />
            </div>
        );
    };

    const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
        // Calculate the options for filtering
        // using the preFilteredRows
        const options = React.useMemo(() => {
            const options = new Set();
            preFilteredRows.forEach((row) => {
                options.add(row.values[id]);
            });
            return [...options.values()];
        }, [id, preFilteredRows]);

        // Render a multi-select box
        return (
            <select
                className='form-control form-control-sm'
                value={filterValue}
                onChange={(e) => {
                    setFilter(e.target.value || undefined);
                }}>
                <option value=''>All</option>
                {options.map((option, i) => (
                    <option key={i} value={option} style={{ textTransform: "capitalize" }}>
                        {option}
                    </option>
                ))}
            </select>
        );
    };
    const clickFunc = (item) => {
        history.push(`/users/${item.id}`);
    };
    const usersListColumns = useMemo(
        () => [
            {
                Header: "Username",
                accessor: "profile.login",
                Cell: titleCell,
                filter: "fuzzyText",
            },
            {
                Header: "Name",
                accessor: "profile.displayName",
                Cell: nameCell,
                filter: "fuzzyText",
            },

            {
                Header: "Status",
                accessor: "status",
                Cell: statusCell,
                Filter: SelectColumnFilter,
            },
        ],
        []
    );
    return (
        <>
            <ListTable
                columns={usersListColumns}
                data={users}
                canExport={true}
                clickable={true}
                selectAllEnabled={false}
                showSelectCheckbox={false}
                idfield={"id"}
                pageslug={"users"}
                filterData={[]}
                clickFunc={clickFunc}
            />
        </>
    );
};

export default List;
