import React from "react";
import { Row, Col, Badge } from "reactstrap";
import StoreDetailField from "./StoreDetailField";

const StoreDetailTab = (data) => {
    const { item, brandcolor } = data;
    const datetimeoptions = {
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
    };
    return (
        <>
            <Row className='mb-3'>
                <StoreDetailField
                    label='Address:'
                    value={
                        <small>
                            <address>
                                <div>{item.Location}</div>
                                <div>{item.Address}</div>
                                {item.Address2 ? <div>item.Address2</div> : ""}
                                <div>
                                    {item.City}, {item.State} {item.ZIP}
                                </div>
                                <div>{item.Country}</div>
                            </address>
                        </small>
                    }
                />
                <StoreDetailField label='Franchisee:' value={item.FranchiseBusinessName} />
            </Row>
            <Row className='mb-3'>
                <StoreDetailField label='Venue:' value={item.Venue} />
                <StoreDetailField label='Status:' value={item.FranchiseStatus} />
            </Row>
            <Row className='mb-3'>
                <StoreDetailField label='POS:' value={item.POS ? item.POS : <Badge color='secondary'>Not Set</Badge>} />
                <StoreDetailField label='Company Owned:' value={item.CompanyOwnedFlag ? "Yes" : "No"} />
            </Row>
            <Row className='mb-3'>
                <StoreDetailField label='Currency:' value={item.CurrencyCode} />
                <StoreDetailField label='Sales Reporting:' value={item.ManualReporting ? "Manual" : "Auto"} />
            </Row>
            <Row className='mb-3'>
                <StoreDetailField label='Primary Contact:' value={item.PrimaryContact ? item.PrimaryContact : <Badge color='secondary'>Not Set</Badge>} />
                <StoreDetailField
                    label='Contact Email:'
                    value={item.PrimaryContactEmail ? <a href={`mailto:${item.PrimaryContactEmail}`}>{item.PrimaryContactEmail.toLowerCase()}</a> : <Badge color='secondary'>Not Set</Badge>}
                />
            </Row>
            <Row className='mb-3'>
                <StoreDetailField label='Phone:' value={item.Phone ? <a href={`tel:${item.Phone}`}>{item.Phone}</a> : <Badge color='secondary'>Not Set</Badge>} />
                <StoreDetailField label='Opened:' value={new Date(item.StoreOpen).toLocaleString("en-US", datetimeoptions)} />
            </Row>
            <Row className='mb-3'>
                <StoreDetailField label='Added:' value={new Date(item.DateAdded).toLocaleString("en-US", datetimeoptions)} />
                <StoreDetailField label='Modified:' value={new Date(item.DateLastUpdated).toLocaleString("en-US", datetimeoptions)} />
            </Row>
        </>
    );
};

export default StoreDetailTab;
