import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

import StoreDisplay from "../components/Stores/StoreDisplay";
import { Alert } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons";

const Stores = () => {
    const params = useParams();
    const stores = useSelector((state) => state.stores);
    const [display, setDisplay] = useState("");
    const [loading, setloading] = useState(
        <Alert color='info' className='text-center'>
            <FontAwesomeIcon icon={faSpinner} spin /> Loading...
        </Alert>
    );
    useEffect(() => {
        if (params.id) {
            const _activestore = _.find(stores, { FranchiseID: params.id });
            if (_activestore) {
                setDisplay(<StoreDisplay item={_activestore} />);
                setloading(false);
            }
        } else {
            setDisplay(<Redirect to='/404' />);
        }
    }, [params, stores]);

    return loading ? loading : display;
};

export default Stores;
