import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import { OktaAuth } from "@okta/okta-auth-js";
import config from "./config";
import Layout from "./components/Layout";

import Main from "./pages/Main";
import Stores from "./pages/Stores";
import Users from "./pages/Users";
import NotFound from "./pages/NotFound";

const oktaAuth = new OktaAuth(config.oidc);

const App = (props) => (
    <Router>
        <Security oktaAuth={oktaAuth}>
            <Switch>
                <Route path='/implicit/callback' component={LoginCallback} />
                <Layout>
                    <SecureRoute path='/' exact component={Main} {...props} />
                    <SecureRoute path='/stores/:id?' exact component={Stores} {...props} />
                    <SecureRoute path='/users/:id?' exact component={Users} {...props} />
                    <SecureRoute path='/404' component={NotFound} {...props} />
                </Layout>
            </Switch>
        </Security>
    </Router>
);

export default App;
